<template>
    <div id="container"  v-bind:style="{ marginTop: '0px' }">
        <div class="row" id="toast_container">
            <div class="col-md-12" v-bind:style="{}">
                <div class="alert alert-danger" role="alert" v-if="showAlert">{{textNotification}}</div>
                <div class="alert alert-success" role="alert" v-if="showConfirm">{{textNotification}}</div>
            </div>
        </div>
        <div v-bind:style="{ marginTop:(showAlert||showConfirm) ? '0px' : '74px'}">
            <label class="form-label" v-bind:style="{ margin: '4px', color: 'dodgerblue' }"><h3><strong>Login</strong></h3></label>

            <div class="row" v-bind:style="{ margin: '4px' }">
                <div class="col-md-4">
                    <input type="text" class="form-control" v-bind:style="{ margin: '4px' }" placeholder="아이디" inputmode="text" v-model="USER_INFO.id">
                    <input type="password" class="form-control" v-bind:style="{ margin: '4px' }" placeholder="비밀번호" v-model="USER_INFO.pw" @keypress.enter="login_check_server">
                    <button class="btn btn-dark button-width-whole" type="button" v-bind:style="{ margin: '4px' }" @click="login_check_server">로그인</button>
                </div>
            </div>
        </div>
        <div v-bind:style="{ marginTop:(showAlert||showConfirm) ? '20px' : '94px'}">
            <div class="row" v-bind:style="{ margin: '4px' }">
                <div class="col-md-2" align="center">
                    <a href="https://play.google.com/store/apps/details?id=com.ismaker.behavioral_activation" target="_blank">Google Play Store</a><br />
                    <img :src="QR_ANDROID" width="160">
                </div>
                <div class="col-md-2" align="center">
                    <a href="https://apps.apple.com/us/app/%EB%82%B4%EB%A7%98/id6451176047" target="_blank">Apple App Store</a>
                    <img :src="QR_IOS" width="160">
                </div>
            </div>
        </div>
        <div class="col-md-4"></div>
    </div>
</template>

<script>
import QrAndroid from '@/assets/qr_android.png';
import QrIos from '@/assets/qr_ios.png';
import axios from "axios";
const SIMSIMI_BA__BASE_API = process.env.VUE_APP_SERVER_URI;
const SIMSIMI_BA__WEB_LOGIN = SIMSIMI_BA__BASE_API + "web_v1/admin_login";

    export default {
        name: 'ba_login',
         data() {
            return {
                USER_INFO: { id: '', pw: '', },

                QR_ANDROID: QrAndroid,
                QR_IOS: QrIos,

                ALERT: 1,
                CONFIRM: 2,
                showAlert: false,
                showConfirm: false,
            }
        },
        methods: {
            login_check_server() {
                if (this.USER_INFO.id.trim().length === 0) {
                    this.showMessage(this.ALERT, "아이디를 입력하세요", 2000);
                } else if (this.USER_INFO.pw.trim().length === 0) {
                    this.showMessage(this.ALERT, "비밀번호를 입력하세요", 2000);
                }
                axios.post(SIMSIMI_BA__WEB_LOGIN, { id: this.USER_INFO.id, pw: this.USER_INFO.pw })
                    .then((response) => {
                        this.showMessage(this.CONFIRM, "로그인 되었습니다", 2000);
                        this.$root.setDoctorId(response.data.doctorId);
                        this.$root.onMenuClick(2);
                    })
                    .catch(() => {
                        this.showMessage(this.ALERT, "오류가 발생했습니다", 2000);
                    })
                    .finally(() => {
                        //do something
                    });
            },
            //Toast
            showMessage(type, msg, duration = 1500) {
                this.textNotification = msg;
                if (type == this.ALERT) {
                    this.showAlert = true;
                    setTimeout(() => {
                        this.showAlert = false;
                        this.textNotification = '';
                    }, duration);
                } else if (type == this.CONFIRM) {
                    this.showConfirm = true;
                    setTimeout(() => {
                        this.showConfirm = false;
                        this.textNotification = '';
                    }, duration);
                }
            },
        }
    }
</script>
  
<style>
    .button-width-whole {
        width: 100%;
    }
</style>