<template>
    <div id="container" v-bind:style="{ marginTop: '0px' }">
        <div class="row" id="toast_container">
            <div class="col-md-12" v-bind:style="{}">
                <div class="alert alert-danger" role="alert" v-if="showAlert">{{textNotification}}</div>
                <div class="alert alert-success" role="alert" v-if="showConfirm">{{textNotification}}</div>
            </div>
        </div>
        <div v-bind:style="{ marginTop:(showAlert||showConfirm) ? '0px' : '74px'}">
            <label class="form-label" v-bind:style="{ margin: '4px', color: 'dodgerblue' }"><h3><strong>User History</strong></h3></label>

            <div class="row" v-bind:style="{ margin: '4px' }">
                <div class="col-md-4">
                    <div class="row">
                        <div class="dropdown">
                            <button class="btn btn-outline-dark dropdown-toggle button-width" type="button" id="dropdownUser" data-bs-toggle="dropdown" aria-expanded="false">
                                {{ this.selectedUser.name === "" ? '사용자 선택' : `[${this.selectedUser.hospital ?? "없음"}] ${this.selectedUser.name} (${this.selectedUser.pid})` }}
                            </button>
                            <ul class="dropdown-menu button-width" aria-labelledby="dropdownUser">
                                <li v-for="(user) in USER_LIST" :key="user.userId">
                                    <a class="dropdown-item" href="#" v-bind:style="{ color:(user.accountId ? 'black' : 'gray') }" @click="getUserData(user.userId)">{{`[${user.hospital ?? "없음"}] ${user.name} (${user.pid})`}}</a>
                                </li>
                            </ul>
                            <button v-if="this.selectedUser.name !== ''" class="btn btn-outline-danger button-width" type="button" v-bind:style="{ marginLeft:'8px' }" @click="confirmDeleteUser()">삭제</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="this.selectedUserData.userName" v-bind:style="{ marginTop: '20px'}">

            <!-- get New Situation Modal -->
            <Modal v-if="MODAL_SHOW" @close="MODAL_SHOW = false" @run="modalButtonYes()">
                <template v-slot:header>
                    <h3>{{MODAL_TITLE}}</h3>
                </template>
                <template v-slot:body>
                    <div v-html="MODAL_CONTENT"></div>
                </template>
            </Modal>

            <ul>
                <li>{{ "사용자: " + this.selectedUserData.userName + " (" + this.selectedUserData.userBirth + ")" }}</li>
                <li>{{ "등록일시: " + this.selectedUserData.regDate.replace("T", " ") }}</li>
                <li>{{ "활성화코드: " + this.selectedUserData.accessCode }}</li>
            </ul>
            <ul v-if="this.selectedUserData.accountId">
                <li>{{ "App 등록일시: " + this.selectedUserData.appRegDate.replace("T", " ") }}</li>
                <li>{{ "App 최종접속: " + ((!this.selectedUserData.accessDateList || this.selectedUserData.accessDateList.length === 0) ? "정보없음" : (this.selectedUserData.accessDateList[this.selectedUserData.accessDateList.length - 1].accessDate + "(" + this.DOW[new Date(this.selectedUserData.accessDateList[this.selectedUserData.accessDateList.length - 1].accessDate).getDate()] + ")")) }}</li>

                <li >{{ "회차 진행정보: " + ((!this.selectedUserData.weekInfoList || this.selectedUserData.weekInfoList.length === 0) ? "정보없음" : "") }}</li>
                <ul v-if="this.selectedUserData.weekInfoList">
                    <li v-for="(weekInfo) in this.selectedUserData.weekInfoList" :key="weekInfo.week">
                        {{ weekInfo.week + "주차: " + weekInfo.weekStartDate + "(" + this.DOW[new Date(weekInfo.weekStartDate).getDay()] + ") ~ " + weekInfo.weekEndDate + "(" + this.DOW[new Date(weekInfo.weekEndDate).getDay()] + ")" }}
                    </li>
                </ul>
                <div v-bind:style="{ marginTop:'20px', marginLeft:'120px' }">
                    <GChart :settings="{ packages: ['calendar'] }" type="Calendar" :data="USER_ACCESS_CHART"
                            :options="{ width:1400, height:400, title:'접속 정보', titleTextStyle:{fontSize:20}, legend:{position:'none'},
                                        calendar:{ cellSize:18 }, noDataPattern:{backgroundColor:'gray', color:'gray'} }" />
                </div>
                <br><br>

                <li >{{ "교육 진행정보: " + ((!this.selectedUserData.studyHistoryList || this.selectedUserData.studyHistoryList.length === 0) ? "정보없음" : `${(this.selectedUserData.studyHistoryList[this.selectedUserData.studyHistoryList.length -1].week)}주차`) }}</li>
                <!-- <li >{{ "교육 진행정보: " + ((!this.selectedUserData.studyHistoryList || this.selectedUserData.studyHistoryList.length === 0) ? "정보없음" : "") }}</li>
                <ul v-if="this.selectedUserData.studyHistoryList">
                    <li v-for="(studyInfo) in this.selectedUserData.studyHistoryList" :key="studyInfo.week">
                        {{ studyInfo.week + "주차: " + studyInfo.studyStep + " / " + studyInfo.studyStepMax }}
                    </li>
                </ul> -->
                <GChart :settings="{ packages:['corechart'] }" type="ComboChart" :data="USER_STUDY_CHART"
                        :options="{ width:1400, height:500, title:'교육 진행 이력', titleTextStyle:{fontSize:20}, legend:{position:'none'}, trendlines:{0:{type:'linear', color:'FireBrick', opacity:0.4} },
                                    seriesType:'bars', series:{1:{type:'line'}, 2:{type:'line'},},
                                    vAxis:{minValue:0, maxValue:1},
                                    hAxis:{minValue:0, maxValue:9, showEveryText:2, textStyle:{fontSize:10}, slantedText:false, slantedTextAngle:90},
                                    chartArea:{left : 160} }" />
                <br><br>

                <li >{{ "PHQ-9 진행정보: " + ((!this.selectedUserData.phq9HistoryList || this.selectedUserData.phq9HistoryList.length === 0) ? "정보없음" : "") }}</li>
                <ul v-if="this.selectedUserData.phq9HistoryList">
                    <li v-for="(phq9Info) in this.selectedUserData.phq9HistoryList" :key="phq9Info.week">
                        {{ phq9Info.week + "주차: " + phq9Info.phq9Score + " / " + phq9Info.phq9ScoreMax + " " + this.parsePhq9Score(phq9Info.phq9Score) }}
                    </li>
                </ul>
                <GChart :settings="{ packages:['corechart'] }" type="ScatterChart" :data="USER_PHQ9_CHART"
                        :options="{ width:1400, height:500, title:'PHQ-9 이력', titleTextStyle:{fontSize:20}, legend:{position:'none'}, trendlines:{0:{type:'linear', color:'FireBrick', opacity:0.4} },
                                    vAxis:{minValue:0, maxValue:27},
                                    hAxis:{minValue:0, maxValue:9, showEveryText:2, textStyle:{fontSize:10}, slantedText:false, slantedTextAngle:90},
                                    chartArea:{left : 160} }" />
                <br><br>


                <li >{{ "활동 기록: " + ((!this.selectedUserData.recordInfoList || this.selectedUserData.recordInfoList.length === 0) ? "정보없음" : "") }}</li>
                <ul v-if="this.selectedUserData.recordInfoList">
                    <li v-for="(weekInfo) in this.selectedUserData.weekInfoList" :key="weekInfo.week">
                        {{ weekInfo.week + "주차: " + this.parseRecordInfo(weekInfo) }}
                    </li>
                </ul>
                <GChart :settings="{ packages:['table'] }" type="Table" :data="USER_RECORD_TABLE"
                        :options="{ width:1400, height:200, title:'활동 기록 긍정/부정', titleTextStyle:{fontSize:20}, legend:{position:'top'},
                                    curveType:'function', pageSize:5, sort:'event',
                                    allowHtml: true, cssClassNames: {tableCell:'small-font-cell' },
                                    chartArea:{left : 160} }" />
                <GChart :settings="{ packages:['corechart'] }" type="ComboChart" :data="USER_RECORD_CHART"
                        :options="{ width:1400, height:500, isStacked:true, title:'활동 기록 작성량 및 감정 분포', titleTextStyle:{fontSize:20}, legend:{position:'top'}, trendlines:{0:{type:'linear', color:'FireBrick', opacity:0.4} },
                                    seriesType:'line', series:{1:{type:'bars', color:'green'}, 2:{type:'bars', color:'gold'}, 3:{type:'bars', color:'purple'} },
                                    hAxis:{showEveryText:2, textStyle:{fontSize:10}, slantedText:true, slantedTextAngle:90},
                                    chartArea:{left : 160} }" />
                <br><br>

                <li >{{ "목표 활동: " + ((!this.selectedUserData.planInfoList || this.selectedUserData.planInfoList.length === 0) ? "정보없음" : "") }}</li>
                <ul v-if="this.selectedUserData.planInfoList">
                    <li v-for="(weekInfo) in this.selectedUserData.weekInfoList" :key="weekInfo.week">
                        {{ weekInfo.week + "주차: " + this.parsePlanInfo(weekInfo) }}
                    </li>
                </ul>
            </ul>
            <GChart :settings="{ packages:['table'] }" type="Table" :data="USER_PLAN_TABLE"
                    :options="{ width:1400, height:200, title:'목표 활동 순위', titleTextStyle:{fontSize:20}, legend:{position:'top'},
                                curveType:'function', pageSize:5, sort:'event',
                                allowHtml: true, cssClassNames: {tableCell:'small-font-cell' },
                                chartArea:{left : 160} }" />
            <GChart :settings="{ packages:['corechart'] }" type="ComboChart" :data="USER_PLAN_CHART"
                    :options="{ width:1400, height:500, title:'목표 활동 작성량 및 달성량', titleTextStyle:{fontSize:20}, legend:{position:'top'}, trendlines:{0:{type:'linear', color:'FireBrick', opacity:0.4} },
                                seriesType:'line', series:{1:{type:'bars'}, 2:{type:'line'},},
                                hAxis:{showEveryText:2, textStyle:{fontSize:10}, slantedText:true, slantedTextAngle:90},
                                chartArea:{left : 160} }" />
            <GChart :settings="{ packages:['corechart'] }" type="PieChart" :data="USER_CATEGORY_CHART"
                    :options="{ width:466, height:500, title:'삶의 가치 영역', titleTextStyle:{fontSize:20}, legend:{position:'top', maxLines:3},
                                chartArea:{left : 160} }" />
            <br><br>

            <!-- <div class="col md-6" :align="'left'">
                <GChart :settings="{ packages:['corechart'] }" type="PieChart" :data="USER_RECORD_CHART"
                        :options="{ title:'활동 기록 작성', titleTextStyle:{fontSize:20}, legend:{position:'right'}, width:700, height:500 }" />
            </div> -->
            <!-- ScatterChart -->
            <!-- <GChart :settings="{ packages:['corechart'] }" type="ScatterChart" :data="USER_RECORD_CHART"
                    :options="{ width:1400, height:500, title:'활동 기록 작성', titleTextStyle:{fontSize:20}, legend:{position:'none'}, trendlines:{0:{type:'linear', color:'FireBrick', opacity:0.4} },
                                hAxis:{showEveryText:2, textStyle:{fontSize:10}, slantedText:true, slantedTextAngle:90},
                                chartArea:{left : 160} }" /> -->

            <!-- <label class="form-label" v-bind:style="{ color: 'black', marginLeft:'8px' }">{{ JSON.stringify(this.selectedUserData) }}</label> -->
        </div>
    </div>
</template>
  
<script>
    import { GChart } from 'vue-google-charts';
    import axios from "axios";
    import Modal from './Modal.vue';
    const SIMSIMI_BA__BASE_API = process.env.VUE_APP_SERVER_URI;
    const SIMSIMI_BA__WEB_GET_USER_LIST = SIMSIMI_BA__BASE_API + "web_v1/get_user_list";
    const SIMSIMI_BA__WEB_GET_USER_DATA = SIMSIMI_BA__BASE_API + "web_v1/get_user_data";
    const SIMSIMI_BA__WEB_DELETE_USER = SIMSIMI_BA__BASE_API + "web_v1/delete_user";
    const PLAN_CATEGORY = ['가족관계', '교육/자기개발', '부부/연인관계', '사회참여/시민의식', '운동/신체 건강', '종교 활동', '일/직업', '일상활동', '정신 건강', '취미/여가', '친구/사회적 관계'];
    //const PLAN_CATEGORY = ['가족관계', '교육/자기개발', '부부/연인관계', '사회참여/시민의식', '신체 건강', '영성/종교', '일/직업', '일상활동', '정신 건강', '취미/여가', '친구/사회적 관계'];
    const CHANGED_PLAN_CATEGORY_BEFORE = ['신체 건강', '영성/종교'];
    const CHANGED_PLAN_CATEGORY_AFTER = ['운동/신체 건강', '종교 활동'];

    export default {
        name: 'ba_view',
        components: {
            GChart,
            Modal,
        },
        data() {
            return {
                doctorId: -1,

                USER_LIST: [],
                selectedUser: {name: "", accountId: null, userId: -1, doctorId: -1, hospital: "", pid: ""},
                selectedUserData: {},

                USER_ACCESS_CHART: [[],],
                USER_STUDY_CHART: [[],],
                USER_PHQ9_CHART: [[],],
                USER_RECORD_TABLE: [['순위', '긍정 활동기록', '부정 활동기록'],
                    ['1', '', ''], ['2', '', ''], ['3', '', ''], ['4', '', ''], ['5', '', ''],
                    ['1', '', ''], ['2', '', ''], ['3', '', ''], ['4', '', ''], ['5', '', ''],
                    ['1', '', ''], ['2', '', ''], ['3', '', ''], ['4', '', ''], ['5', '', ''],
                    ['1', '', ''], ['2', '', ''], ['3', '', ''], ['4', '', ''], ['5', '', ''],
                    ['1', '', ''], ['2', '', ''], ['3', '', ''], ['4', '', ''], ['5', '', ''],
                    ['1', '', ''], ['2', '', ''], ['3', '', ''], ['4', '', ''], ['5', '', ''],
                    ['1', '', ''], ['2', '', ''], ['3', '', ''], ['4', '', ''], ['5', '', ''],
                    ['1', '', ''], ['2', '', ''], ['3', '', ''], ['4', '', ''], ['5', '', ''],
                ],
                USER_RECORD_CHART: [[],],
                USER_PLAN_TABLE: [['순위', '항우울 활동', '삶의 가치 분류'], ['1', '', ''], ['2', '', ''], ['3', '', ''], ['4', '', ''], ['5', '', '']],
                USER_PLAN_CHART: [[],],
                USER_CATEGORY_CHART: [['가치영역','누적']],

                DOW: ["일", "월", "화", "수", "목", "금", "토"],

                MODAL_TYPE: { CANNOT_RUN:0, CONFIRM_DELETE:11, },
                MODAL_TYPE_FOR_YES: 0,
                MODAL_SHOW: false,
                MODAL_TITLE: '',
                MODAL_CONTENT: '',

                ALERT: 1,
                CONFIRM: 2,
                showAlert: false,
                showConfirm: false,
            }
        },
        mounted() {
            this.doctorId = this.$root.getDoctorId();
            if (this.doctorId == -1) {
                this.showMessage(this.ALERT, "로그인 해주세요", 2000);
            } else {
                this.getUserListFromServer();
            }
        },
        methods: {
            initSelectedUserData() {
                this.selectedUserData = {};
                this.USER_RECORD_TABLE = [['순위', '긍정 활동기록', '부정 활동기록'],
                    ['1', '', ''], ['2', '', ''], ['3', '', ''], ['4', '', ''], ['5', '', ''],
                    ['1', '', ''], ['2', '', ''], ['3', '', ''], ['4', '', ''], ['5', '', ''],
                    ['1', '', ''], ['2', '', ''], ['3', '', ''], ['4', '', ''], ['5', '', ''],
                    ['1', '', ''], ['2', '', ''], ['3', '', ''], ['4', '', ''], ['5', '', ''],
                    ['1', '', ''], ['2', '', ''], ['3', '', ''], ['4', '', ''], ['5', '', ''],
                    ['1', '', ''], ['2', '', ''], ['3', '', ''], ['4', '', ''], ['5', '', ''],
                    ['1', '', ''], ['2', '', ''], ['3', '', ''], ['4', '', ''], ['5', '', ''],
                    ['1', '', ''], ['2', '', ''], ['3', '', ''], ['4', '', ''], ['5', '', ''],
                ];
                this.USER_PLAN_TABLE = [['순위', '항우울 활동', '삶의 가치 분류'], ['1', '', ''], ['2', '', ''], ['3', '', ''], ['4', '', ''], ['5', '', '']];
            },
            getUserListFromServer() {
                this.initSelectedUserData();
                axios.post(SIMSIMI_BA__WEB_GET_USER_LIST, {
                    "doctorId": this.doctorId,
                })
                .then((response) => {
                    this.showMessage(this.CONFIRM, "사용자 목록을 로드했습니다", 2000);
                    console.log(response.data);
                    if (response.data) {
                        this.USER_LIST = response.data;
                    } else {
                        this.USER_LIST = [];
                    }
                })
                .catch((error) => {
                    this.USER_LIST = [];
                    if (error.response.status === 404) {
                        this.showMessage(this.ALERT, "등록된 사용자가 없습니다", 2000);
                    } else {
                        this.showMessage(this.ALERT, "사용자 목록을 로드하지 못했습니다", 2000);
                    }
                })
                .finally(() => {
                    //do something
                });
            },
            getUserData(userId) {
                console.log("GET USER DATA:", userId);

                this.initSelectedUserData();
                this.selectedUser = this.USER_LIST.filter(item => item.userId == userId)[0];

                const lastTime = JSON.parse(localStorage.getItem("last_get_time_stat_session_" + userId)) || "";
                if (lastTime != "" && lastTime + 600000 > Date.now()) {
                    const indxedDB = window.indexedDB;
                    if (indxedDB) {
                        const request = indxedDB.open('baDB');
                        request.onerror = () => {
                            this.getUserDataFromServer(userId);
                        };
                        request.onsuccess = (event) => {
                            const db = event.target.result;
                            db.transaction('stat_datas').objectStore('stat_datas').get(userId).onsuccess = (event) => {
                                if (event.target.result) {
                                    //this.showMessage(this.CONFIRM, "10분이 경과되지 않아 이전 데이터를 표시합니다", 2000);
                                    this.selectedUserData = JSON.parse(event.target.result.value);
                                    this.makeChartDatas();
                                } else {
                                    this.getUserDataFromServer(userId);
                                }
                            };
                        };
                        request.onupgradeneeded = (event) => {
                            const db = event.target.result;
                            let objectStore = db.createObjectStore("stat_datas", {keyPath: 'id'});
                            objectStore.transaction.oncomplete = (event) => {
                                console.log('IN onupgradeneeded AT server_getSessionDatas ' + event.target.mode);
                            };
                        };
                    } else {
                        this.getUserDataFromServer(userId);
                    }
                } else {
                    this.getUserDataFromServer(userId);
                }
            },
            getUserDataFromServer(userId) {
                localStorage.setItem("last_get_time_stat_session_" + userId, JSON.stringify(Date.now()));

                axios.post(SIMSIMI_BA__WEB_GET_USER_DATA, {
                    "doctorId": this.selectedUser.doctorId,
                    "userId": this.selectedUser.userId,
                })
                .then((response) => {
                    this.showMessage(this.CONFIRM, "사용자 데이터를 로드했습니다", 2000);
                    this.selectedUserData = response.data;
                    this.makeChartDatas();

                    try {
                        // https://developer.mozilla.org/ko/docs/Web/API/IndexedDB_API
                        const indxedDB = window.indexedDB;
                        if (indxedDB) {
                            const request = indxedDB.open('baDB');
                            request.onsuccess = (event) => {
                                const db = event.target.result;
                                const sessionObjectStore = db.transaction("stat_datas", "readwrite").objectStore("stat_datas");
                                sessionObjectStore.put({ id: userId, regTime: Date.now(), value: JSON.stringify(response.data) });
                            };
                            request.onupgradeneeded = (event) => {
                                const db = event.target.result;
                                let objectStore = db.createObjectStore("stat_datas", {keyPath: 'id'});
                                objectStore.transaction.oncomplete = (event) => {
                                    console.log('IN onupgradeneeded AT processSessionDatas ' + event.target.mode);
                                };
                            };
                        }
                    } catch(error) {
                        alert(error);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status === 404) {
                        this.showMessage(this.ALERT, "등록된 사용자가 없습니다", 2000);
                    } else {
                        this.showMessage(this.ALERT, "사용자 데이터를 로드하지 못했습니다", 2000);
                    }
                })
                .finally(() => {
                    //do something
                });
            },
            parsePhq9Score(score) {
                if (score <= 4) {
                    return " (0-4점: 정상 범위)";
                } else if (score <= 9) {
                    return " (5-9점: 경도의 우울)";
                } else if (score <= 14) {
                    return " (10-14점: 중등도의 우울)";
                } else if (score <= 19) {
                    return " (15-19점: 중고도의 우울)";
                } else if (score <= 27) {
                    return " (20-27점: 고도의 우울)";
                } else {
                    return " (E: 점수범위 초과)";
                }
            },
            parseRecordInfo(weekInfo) {
                const filterdRecords = this.selectedUserData.recordInfoList.filter(item => (weekInfo.weekStartDate <= item.recordDate && item.recordDate <= weekInfo.weekEndDate));

                if (filterdRecords.length === 0) {
                    return "없음";
                }
                const weekStartDate = new Date(weekInfo.weekStartDate);
                const weekCount = [0,0,0,0,0,0,0];
                let emotionLikertSum = 0;
                const goodActivities = [];
                const badActivities = [];
                filterdRecords.forEach((record) => {
                    const recordDate = new Date(record.recordDate);
                    weekCount[(recordDate.getTime() - weekStartDate.getTime()) / (24 * 60 * 60 * 1000)] += 1;
                    emotionLikertSum += record.emotionLikert;

                    if (record.emotionLikert > 0) {
                        const foundIndex = goodActivities.findIndex(item => item.activity === record.activity);
                        if (foundIndex !== -1) {
                            goodActivities[foundIndex].frequency++;
                        } else {
                            goodActivities.push({ activity:record.activity, frequency:1 });
                        }
                    } else if (record.emotionLikert < 0) {
                        const foundIndex = badActivities.findIndex(item => item.activity === record.activity);
                        if (foundIndex !== -1) {
                            badActivities[foundIndex].frequency++;
                        } else {
                            badActivities.push({ activity:record.activity, frequency:1 });
                        }
                    }
                });
                const sortedGoodActivities = goodActivities.sort((a, b) => b.frequency - a.frequency);
                const goodActivityString = sortedGoodActivities.reduce((p, c) => (p === "" ? "" : (p + ", ")) + c.activity, "");
                const sortedBadActivities = badActivities.sort((a, b) => b.frequency - a.frequency);
                const badActivityString = sortedBadActivities.reduce((p, c) => (p === "" ? "" : (p + ", ")) + c.activity, "");

                sortedGoodActivities.length > 0 && sortedGoodActivities.some((sortedActivities, index) => {
                    if (index > 4) return true;
                    this.USER_RECORD_TABLE[(weekInfo.week - 1) * 5 + (index+1)][1] = sortedActivities.activity;
                });
                sortedBadActivities.length > 0 && sortedBadActivities.some((sortedActivities, index) => {
                    if (index > 4) return true;
                    this.USER_RECORD_TABLE[(weekInfo.week - 1) * 5 + (index+1)][2] = sortedActivities.activity;
                });

                return (filterdRecords.length + "개 (" + weekCount + "), 평균 감정척도: " + (emotionLikertSum/filterdRecords.length).toFixed(2)) + ", GOOD: " + goodActivityString + ", BAD: " + badActivityString;
            },
            parsePlanInfo(weekInfo) {
                const filterdPlans = this.selectedUserData.planInfoList.filter(item => (weekInfo.weekStartDate <= item.planDate && item.planDate <= weekInfo.weekEndDate));

                if (filterdPlans.length === 0) {
                    return "없음";
                }
                const weekStartDate = new Date(weekInfo.weekStartDate);
                const weekCount = [0,0,0,0,0,0,0];
                let emotionLikertSum = 0;
                const goodPlanCategories = [];
                const badPlanCategories = [];
                filterdPlans.forEach((plan) => {
                    const planDate = new Date(plan.planDate);
                    weekCount[(planDate.getTime() - weekStartDate.getTime()) / (24 * 60 * 60 * 1000)] += 1;
                    emotionLikertSum += plan.emotionLikert;

                    if (plan.emotionLikert > 0) {
                        const foundCategoryIndex = goodPlanCategories.findIndex(item => item.planCategory === plan.planCategory);
                        if (foundCategoryIndex !== -1) {
                            goodPlanCategories[foundCategoryIndex].frequency++;

                            const foundPlanIndex = goodPlanCategories[foundCategoryIndex].plans.findIndex(item => item.plan === plan.plan);
                            if (foundPlanIndex !== -1) {
                                goodPlanCategories[foundCategoryIndex].plans[foundPlanIndex].frequency++;
                            } else {
                                goodPlanCategories[foundCategoryIndex].plans.push({ plan:plan.plan, frequency:1 });
                            }
                        } else {
                            goodPlanCategories.push({ planCategory:plan.planCategory, frequency:1, plans: [{plan:plan.plan, frequency:1}] });
                        }
                    } else if (plan.emotionLikert < 0) {
                        const foundCategoryIndex = badPlanCategories.findIndex(item => item.planCategory === plan.planCategory);
                        if (foundCategoryIndex !== -1) {
                            badPlanCategories[foundCategoryIndex].frequency++;

                            const foundPlanIndex = badPlanCategories[foundCategoryIndex].plans.findIndex(item => item.plan === plan.plan);
                            if (foundPlanIndex !== -1) {
                                badPlanCategories[foundCategoryIndex].plans[foundPlanIndex].frequency++;
                            } else {
                                badPlanCategories[foundCategoryIndex].plans.push({ plan:plan.plan, frequency:1 });
                            }
                        } else {
                            badPlanCategories.push({ planCategory:plan.planCategory, frequency:1, plans: [{plan:plan.plan, frequency:1}] });
                        }
                    }
                });
                goodPlanCategories.forEach((planCategory, planCategoryIndex) => {
                    goodPlanCategories[planCategoryIndex].plan = planCategory.plans.sort((a, b) => b.frequency - a.frequency);
                });
                badPlanCategories.forEach((planCategory, planCategoryIndex) => {
                    badPlanCategories[planCategoryIndex].plan = planCategory.plans.sort((a, b) => b.frequency - a.frequency);
                });
                const sortedGoodPlanCategories = goodPlanCategories.sort((a, b) => b.frequency - a.frequency);
                const goodPlanCategoryString = sortedGoodPlanCategories.length === 0 ? "없음" : sortedGoodPlanCategories.reduce((p, c) => (p === "" ? "" : (p + ", ")) + c.planCategory + "(" + c.plans.reduce((pp, cp) => (pp === "" ? "" : (pp + ", ")) + cp.plan, "") + ")", "");
                const sortedBadPlanCategories = badPlanCategories.sort((a, b) => b.frequency - a.frequency);
                const badPlanCategoryString = sortedBadPlanCategories.length === 0 ? "없음" : sortedBadPlanCategories.reduce((p, c) => (p === "" ? "" : (p + ", ")) + c.planCategory + "(" + c.plans.reduce((pp, cp) => (pp === "" ? "" : (pp + ", ")) + cp.plan, "") + ")", "");

                return (filterdPlans.length + "개 (" + weekCount + "), 평균 감정척도: " + (emotionLikertSum/filterdPlans.length).toFixed(2)) + ", GOOD: " + goodPlanCategoryString + ", BAD: " + badPlanCategoryString;
            },
            makeChartDatas() {
                this.USER_ACCESS_CHART = [];
                this.USER_ACCESS_CHART[0] = [{type:'date', id:'Date'}, {type:'number', id:'access'}];
                if (this.selectedUserData.accessDateList) {
                    this.selectedUserData.accessDateList.forEach(accessInfo => {
                        this.USER_ACCESS_CHART.push([new Date(accessInfo.accessDate), 1]);
                    });
                    console.log(this.USER_ACCESS_CHART);
                }

                this.USER_RECORD_CHART = Array.from(Array(8*7 + 1), () => Array(5).fill(0));
                this.USER_RECORD_CHART[0][0] = '날짜';
                this.USER_RECORD_CHART[0][1] = '개수';
                this.USER_RECORD_CHART[0][2] = '좋음';
                this.USER_RECORD_CHART[0][3] = '중립';
                this.USER_RECORD_CHART[0][4] = '나쁨';
                for (let tempWeekIndex=0;tempWeekIndex<8;tempWeekIndex++) {
                    for (let tempDayIndex=0; tempDayIndex<7; tempDayIndex++) {
                        this.USER_RECORD_CHART[tempWeekIndex * 7 + tempDayIndex + 1][0] = `${tempWeekIndex + 1}주 ${(tempDayIndex + 1)}일`;
                    }
                }
                if (this.selectedUserData.weekInfoList && this.selectedUserData.recordInfoList) {
                    for (const weekInfo of this.selectedUserData.weekInfoList) {
                        const filterdRecords = this.selectedUserData.recordInfoList.filter(item => (weekInfo.weekStartDate <= item.recordDate && item.recordDate <= weekInfo.weekEndDate));
                        const weekDataCount = Array(7).fill({count:0, good:0, nutral:0, bad:0}, 0);
                        if (filterdRecords.length > 0) {
                            const weekStartDate = new Date(weekInfo.weekStartDate);
                            filterdRecords.forEach((record) => {
                                const recordDate = new Date(record.recordDate);
                                const daysIndex = (recordDate.getTime() - weekStartDate.getTime()) / (24 * 60 * 60 * 1000);
                                weekDataCount[daysIndex] = {
                                    count: weekDataCount[daysIndex].count + 1,
                                    good: weekDataCount[daysIndex].good + (record.emotionLikert > 0 ? 1 : 0),
                                    bad: weekDataCount[daysIndex].bad + (record.emotionLikert < 0 ? 1 : 0),
                                    nutral: weekDataCount[daysIndex].nutral + (record.emotionLikert == 0 ? 1 : 0)
                                };
                            });
                        }
                        weekDataCount.forEach((weekCount, countIndex) => {
                            const dataIndex = (weekInfo.week - 1) * 7 + (1 + countIndex);
                            this.USER_RECORD_CHART[dataIndex][0] = `${weekInfo.week}주 ${(1 + countIndex)}일`;
                            this.USER_RECORD_CHART[dataIndex][1] = weekCount.count;
                            this.USER_RECORD_CHART[dataIndex][2] = weekCount.good;
                            this.USER_RECORD_CHART[dataIndex][3] = weekCount.nutral;
                            this.USER_RECORD_CHART[dataIndex][4] = weekCount.bad;
                        });
                    }
                    console.log(this.USER_RECORD_CHART);
                }

                this.USER_PLAN_CHART = Array.from(Array(8*7 + 1), () => Array(3).fill(0));
                this.USER_PLAN_CHART[0][0] = '날짜';
                this.USER_PLAN_CHART[0][1] = '개수';
                this.USER_PLAN_CHART[0][2] = '달성';
                for (let tempWeekIndex=0;tempWeekIndex<8;tempWeekIndex++) {
                    for (let tempDayIndex=0; tempDayIndex<7; tempDayIndex++) {
                        this.USER_PLAN_CHART[tempWeekIndex * 7 + tempDayIndex + 1][0] = `${tempWeekIndex + 1}주 ${(tempDayIndex + 1)}일`;
                    }
                }
                if (this.selectedUserData.weekInfoList && this.selectedUserData.planInfoList) {
                    for (const weekInfo of this.selectedUserData.weekInfoList) {
                        const filterdPlans = this.selectedUserData.planInfoList.filter(item => (weekInfo.weekStartDate <= item.planDate && item.planDate <= weekInfo.weekEndDate));
                        const weekDataCount = Array(7).fill({count:0, done:0});
                        if (filterdPlans.length > 0) {
                            const weekStartDate = new Date(weekInfo.weekStartDate);
                            filterdPlans.forEach((plan) => {
                                const planDate = new Date(plan.planDate);
                                const daysIndex = (planDate.getTime() - weekStartDate.getTime()) / (24 * 60 * 60 * 1000);
                                weekDataCount[daysIndex] = {
                                    count: weekDataCount[daysIndex].count + 1,
                                    done: weekDataCount[daysIndex].done + (plan.planStatus === "done" ? 1 : 0)
                                };
                            });
                        }
                        weekDataCount.forEach((weekCount, countIndex) => {
                            const dataIndex = (weekInfo.week - 1) * 7 + (1 + countIndex);
                            this.USER_PLAN_CHART[dataIndex][0] = `${weekInfo.week}주 ${(1 + countIndex)}일`;
                            this.USER_PLAN_CHART[dataIndex][1] = weekCount.count;
                            this.USER_PLAN_CHART[dataIndex][2] = weekCount.done;
                        });
                    }
                    console.log(this.USER_PLAN_CHART);
                }

                //달성된 항우울 활동 점수 합계 순위
                //달성된 항우울 활동 카테고리 카운트
                this.USER_PLAN_TABLE = [['순위', '항우울 활동', '삶의 가치 분류'], ['1', '', ''], ['2', '', ''], ['3', '', ''], ['4', '', ''], ['5', '', '']];
                this.USER_CATEGORY_CHART = Array.from(Array(11 + 1), () => Array(2).fill(0));
                this.USER_CATEGORY_CHART[0] = ['가치영역','누적'];
                if (this.selectedUserData.planInfoList) {
                    const planCategoryCountList = [];
                    const planRankList = [];
                    this.selectedUserData.planInfoList.forEach((planInfo) => {
                        if (planInfo.planStatus === "done") {
                            const foundCategoryIndex = planCategoryCountList.findIndex(item => item.planCategory === planInfo.planCategory);
                            if (foundCategoryIndex !== -1) {
                                planCategoryCountList[foundCategoryIndex].count += 1;
                            } else {
                                planCategoryCountList.push({ planCategory: planInfo.planCategory, count: 1 });
                            }
                            const foundPlanIndex = planRankList.findIndex(item => (item.planCategory === planInfo.planCategory && item.plan === planInfo.plan));
                            if (foundPlanIndex !== -1) {
                                planRankList[foundPlanIndex].sum += planInfo.emotionLikert;
                            } else {
                                planRankList.push({ planCategory: planInfo.planCategory, plan: planInfo.plan, sum: planInfo.emotionLikert });
                            }
                        }
                    });

                    const sortedPlanRankList = planRankList.sort((a, b) => b.sum - a.sum);
                    sortedPlanRankList.length > 0 && sortedPlanRankList.some((planRank, planRankIndex) => {
                        if (planRankIndex > 4) return true;
                        this.USER_PLAN_TABLE[planRankIndex + 1][1] = planRank.plan;
                        this.USER_PLAN_TABLE[planRankIndex + 1][2] = planRank.planCategory;
                    });

                    const sortedPlanCategoryCountList = planCategoryCountList.sort((a, b) => b.count - a.count);
                    PLAN_CATEGORY.forEach((cetegory, categoryIndex) => {
                        this.USER_CATEGORY_CHART[categoryIndex + 1][0] = cetegory;
                        this.USER_CATEGORY_CHART[categoryIndex + 1][1] = 0;
                        sortedPlanCategoryCountList.some((planCategoryInfo) => {
                            if (cetegory === planCategoryInfo.planCategory) {
                                this.USER_CATEGORY_CHART[categoryIndex + 1][1] += planCategoryInfo.count;
                                return true;
                            }
                            const prevFoundIndex = CHANGED_PLAN_CATEGORY_AFTER.indexOf(cetegory);
                            if (prevFoundIndex !== -1 && CHANGED_PLAN_CATEGORY_BEFORE[prevFoundIndex] === planCategoryInfo.planCategory) {
                                this.USER_CATEGORY_CHART[categoryIndex + 1][1] += planCategoryInfo.count;
                                return true;
                            }
                        });
                    });
                }
                console.log(this.USER_CATEGORY_CHART);

                //추세선 쓰려면 유효한 데이터만 입력
                this.USER_PHQ9_CHART = [];
                this.USER_PHQ9_CHART.push(['주차', '점수']);
                if (this.selectedUserData.weekInfoList && this.selectedUserData.phq9HistoryList) {
                    for (const weekInfo of this.selectedUserData.weekInfoList) {
                        const filterdPhq9 = this.selectedUserData.phq9HistoryList.filter(item => (weekInfo.week === item.week));
                        if (filterdPhq9.length > 0) {
                            this.USER_PHQ9_CHART.push([weekInfo.week, filterdPhq9[0].phq9Score]);
                        } else {
                            this.USER_PHQ9_CHART.push([weekInfo.week, 0]);
                        }
                    }
                    console.log(this.USER_PHQ9_CHART);
                }

                //추세선 쓰려면 유효한 데이터만 입력
                this.USER_STUDY_CHART = [];
                this.USER_STUDY_CHART.push(['주차', '진행률']);
                if (this.selectedUserData.studyHistoryList) {
                    for (const weekInfo of this.selectedUserData.weekInfoList) {
                        const filterdStudy = this.selectedUserData.studyHistoryList.filter(item => (weekInfo.week === item.week));
                        if (filterdStudy.length > 0) {
                            this.USER_STUDY_CHART.push([weekInfo.week, filterdStudy.length / filterdStudy[0].studyStepMax]);
                        } else {
                            this.USER_STUDY_CHART.push([weekInfo.week, 0]);
                        }
                    }
                    console.log(this.USER_STUDY_CHART);
                }
            },
            confirmDeleteUser() {
                if (this.selectedUser && this.selectedUser.userId !== -1) {
                    this.MODAL_TYPE_FOR_YES = this.MODAL_TYPE.CONFIRM_DELETE;
                    this.MODAL_TITLE = '주의';
                    this.MODAL_CONTENT = '사용자를 삭제하면 다시 복구할 수 없습니다.<br/>계속하시겠습니까?'
                    this.MODAL_SHOW = true;
                } else {
                    this.showMessage(this.ALERT, "사용자 정보를 확인할 수 없습니다", 2000);
                }
            },
            deleteUserFromServer() {
                axios.post(SIMSIMI_BA__WEB_DELETE_USER, {
                    "doctorId": this.selectedUser.doctorId,
                    "userId": this.selectedUser.userId,
                })
                .then((response) => {
                    if (response.data.success) {
                        this.showMessage(this.CONFIRM, "사용자를 삭제했습니다", 1000);
                        this.selectedUser = {name: "", accountId: null, userId: -1, doctorId: -1, hospital: "", pid: ""};
                        this.selectedUserData = {};
                        this.USER_LIST = [];
                        setTimeout(this.getUserListFromServer, 1000);
                    } else {
                        this.showMessage(this.ALERT, "사용자를 삭제중 문제가 발생했습니다", 2000);
                    }
                })
                .catch((error) => {
                    this.showMessage(this.ALERT, "사용자를 삭제중 문제가 발생했습니다\n" + error, 2000);
                })
                .finally(() => {
                    //do something
                });
            },
            //Toast
            showMessage(type, msg, duration = 1500) {
                this.textNotification = msg;
                if (type == this.ALERT) {
                    this.showAlert = true;
                    setTimeout(() => {
                        this.showAlert = false;
                        this.textNotification = '';
                    }, duration);
                } else if (type == this.CONFIRM) {
                    this.showConfirm = true;
                    setTimeout(() => {
                        this.showConfirm = false;
                        this.textNotification = '';
                    }, duration);
                }
            },
            modalButtonYes() {
                if (this.MODAL_TYPE_FOR_YES === this.MODAL_TYPE.CONFIRM_DELETE) {
                    this.deleteUserFromServer();
                }
                this.MODAL_SHOW = false;
                this.MODAL_TYPE_FOR_YES = this.CANNOT_RUN;
            },
        }
    }
</script>

<style>
.small-font-cell {
  font-size: 12px;
  text-align: center;
  width: 600px;
  height: 20px;
}
</style>