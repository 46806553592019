<template>
  <div class="app-alert-area alert alert-dismissible fade show" :class="notificationLevvelClass" role="alert" v-bind:style="{ marginTop: '0px', marginBottom: '0px' }" v-if="notification.length > 0">
      {{notification}}
      <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="onNotificationClose"></button>
  </div>
  <div class="app-middle-contents" :ref="'app-middle-contents'" >
  <!-- LEST MENU -->
  <ul class="app-navi-area">    
    <li class="app-navi-area"><br><h4 v-bind:style="{ color:'#FFE14E' }">닥터심심이</h4></li>
    <!-- <li class="app-navi-area"><a class="selected" v-if="viewVisible[1] == true" align="center">등록</a><a class="normal" href="#" v-if="viewVisible[1] == false" @click="onMenuClick(1)" align="center">등록</a></li> -->
    <li class="app-navi-area"><a class="selected" v-if="viewVisible[2] == true" align="center">열람</a><a class="normal" href="#" v-if="viewVisible[2] == false" @click="onMenuClick(2)" align="center">열람</a></li>
    <!-- <li class="app-navi-area"><a class="selected" v-if="viewVisible[0] == true" align="center">설명</a><a class="normal" href="#" v-if="viewVisible[0] == false" @click="onMenuClick(0)" align="center">설명</a></li>
    <li class="app-navi-area"><a class="selected" v-if="viewVisible[6] == true" align="center">생성</a><a class="normal" href="#" v-if="viewVisible[6] == false" @click="onMenuClick(6)" align="center">생성</a></li>
    <li class="app-navi-area"><a class="selected" v-if="viewVisible[5] == true" align="center">수정</a><a class="normal" href="#" v-if="viewVisible[5] == false" @click="onMenuClick(5)" align="center">수정</a></li>
    <li class="app-navi-area"><a class="selected" v-if="viewVisible[1] == true" align="center">열람</a><a class="normal" href="#" v-if="viewVisible[1] == false" @click="onMenuClick(1)" align="center">열람</a></li>
    <li class="app-navi-area"><a class="selected" v-if="viewVisible[2] == true" align="center">통계</a><a class="normal" href="#" v-if="viewVisible[2] == false" @click="onMenuClick(2)" align="center">통계</a></li>
    <li class="app-navi-area"><a class="selected" v-if="viewVisible[7] == true" align="center">투표</a><a class="normal" href="#" v-if="viewVisible[7] == false" @click="onMenuClick(7)" align="center">투표</a></li>
    <li class="app-navi-area"><a class="selected" v-if="viewVisible[4] == true" align="center">검수</a><a class="normal" href="#" v-if="viewVisible[4] == false" @click="onMenuClick(4)" align="center">검수</a></li>
    <li class="app-navi-area"><a class="selected" v-if="viewVisible[3] == true" align="center">이력</a><a class="normal" href="#" v-if="viewVisible[3] == false" @click="onMenuClick(3)" align="center">이력</a></li> -->

    
    <!--<li class="navi-area"><br><h4>TODAGI API 성능테스트</h4></li>
    <li class="navi-area"><h5>with CNH</h5></li>
    
    <li class="navi-area"><a class="selected" v-if="viewVisible[3] == true">AICR 응답시간 및 정상응답률</a><a class="normal" href="#" v-if="viewVisible[3] == false" @click="onMenuClick(3)">AICR 응답시간 및 정상응답률</a></li>
    <li class="navi-area"><a class="selected" v-if="viewVisible[4] == true">AICR-A 정상발송률</a><a class="normal" href="#" v-if="viewVisible[4] == false" @click="onMenuClick(4)">AICR-A 정상발송률</a></li>
    <li class="navi-area"><a class="selected" v-if="viewVisible[2] == true">DBSC 응답시간 및 정상응답률</a><a class="normal" href="#" v-if="viewVisible[2] == false" @click="onMenuClick(2)">DBSC 응답시간 및 정상응답률</a></li>
    <li class="navi-area"><a class="selected" v-if="viewVisible[7] == true">토다기와 대화하기</a><a class="normal" href="#" v-if="viewVisible[7] == false" @click="onMenuClick(7)">토다기와 대화하기</a></li>
    <li class="navi-area"><a class="selected" v-if="viewVisible[8] == true">계약서 수정</a><a class="normal" href="#" v-if="viewVisible[8] == false" @click="onMenuClick(8)">계약서 수정</a></li>-->

    <!--<li class="navi-area"><br><h4>AI바우처 지원사업</h4></li>
    <li class="navi-area"><h5>AI 감성대화 챗봇 기반 정신건강 상담 서비스</h5></li>
    <li class="navi-area"><a class="selected" v-if="viewVisible[0] == true">테스트 개요</a><a class="normal" href="#" v-if="viewVisible[0] == false" @click="onMenuClick(0)">테스트 개요</a></li>
    <li class="navi-area"><a class="selected" v-if="viewVisible[1] == true">1. DBSC 모델 테스트</a><a class="normal" href="#" v-if="viewVisible[1] == false" @click="onMenuClick(1)">1. DBSC 모델 테스트</a></li>
    <li class="navi-area"><a class="selected" v-if="viewVisible[2] == true">2. DBSC 응답시간 및 정상응답률</a><a class="normal" href="#" v-if="viewVisible[2] == false" @click="onMenuClick(2)">2. DBSC 응답시간 및 정상응답률</a></li>
    <li class="navi-area"><a class="selected" v-if="viewVisible[3] == true">3. AICR 응답시간 및 정상응답률</a><a class="normal" href="#" v-if="viewVisible[3] == false" @click="onMenuClick(3)">3. AICR 응답시간 및 정상응답률</a></li>
    <li class="navi-area"><a class="selected" v-if="viewVisible[4] == true">4. AICR-A 정상발송률</a><a class="normal" href="#" v-if="viewVisible[4] == false" @click="onMenuClick(4)">4. AICR-A 정상발송률</a></li>
    <br><br><br><hr class="dropdown-divider"/>-->
    <!--<li class="navi-area"><br><h4>휴먼케어 컨텐츠 개발지원사업</h4></li>
    <li class="navi-area"><h5>AI 감성대화 챗봇 기반 정신건강 대화 콘텐츠 개발</h5></li>-->
    <!--<li class="navi-area"><a class="selected" v-if="viewVisible[5] == true">5. 음성인식 속도</a><a class="normal" href="#" v-if="viewVisible[5] == false" @click="onMenuClick(5)">5. 음성인식 속도</a></li>-->
    <!--<li class="navi-area"><a class="selected" v-if="viewVisible[7] == true">사용자 대화지속 횟수 테스트</a><a class="normal" href="#" v-if="viewVisible[7] == false" @click="onMenuClick(7)">사용자 대화지속 횟수 테스트</a></li>-->
  </ul>

  <!-- CONTENTS AREA -->
  <div class="app-contents-area">
    <!-- <test00 v-if="viewVisible[0] == true"></test00>
    <test01 v-if="viewVisible[1] == true"></test01>
    <test02 v-if="viewVisible[2] == true"></test02>
    <test03 v-if="viewVisible[3] == true"></test03>
    <test04 v-if="viewVisible[4] == true"></test04>
    <test05 v-if="viewVisible[5] == true"></test05>
    <test06 v-if="viewVisible[6] == true"></test06>
    <test07 v-if="viewVisible[7] == true"></test07>
    <test08 v-if="viewVisible[8] == true"></test08> -->
    <ba_login v-if="viewVisible[0] == true"></ba_login>
    <ba_register v-if="viewVisible[1] == true"></ba_register>
    <ba_view v-if="viewVisible[2] == true"></ba_view>
  </div>
  </div>
</template>

<script>
// import test00 from '@/components/test00.vue'
// import test01 from '@/components/test01.vue'
// import test02 from '@/components/test02.vue'
// import test03 from '@/components/test03.vue'
// import test04 from '@/components/test04.vue'
// import test05 from '@/components/test05.vue'
// import test06 from '@/components/test06.vue'
// import test07 from '@/components/test07.vue'
// import test08 from '@/components/test08.vue'
import ba_login from '@/components/ba_login.vue'
import ba_register from '@/components/ba_register.vue'
import ba_view from '@/components/ba_view.vue'
import axios from "axios";

require('dotenv').config();
//const BLENDER_BOT__BASE_API = process.env.VUE_APP_SERVER_URI;
//const BLENDER_BOT__GET_NOTIFICATION = BLENDER_BOT__BASE_API + "getNotification";

export default {
  name: 'App',
  data() {
    return {
      viewVisible: [true, false, false, false, false, false, false, false, false, false],
      notification: '',
      currentNotificationId: 0,
      seenNotificationId: JSON.parse(localStorage.getItem("notification_id")) || "",
      notificationLevvelClass: 'alert-warning',

      ENV_VUE_APP_INSPECTION: false,

      loginDoctorId: -1,
    };
  },
  components: {
    // test00,
    // test01,
    // test02,
    // test03,
    // test04,
    // test05,
    // test06,
    // test07,
    // test08,
    ba_login,
    ba_register,
    ba_view,
  },
  mounted() {
    axios.interceptors.request.use(function (config) {
      // 요청이 전달되기 전에 작업 수행
      config.headers = {'SimSimi-Api-Version': process.env.VUE_APP_API_VERSION};
      return config;
    }, function (error) {
      // 요청 오류가 있는 작업 수행
      return Promise.reject(error);
    });

    //setInterval(this.checkNotification, 10 * 60 * 1000); //10분마다 공지 확인
    //this.checkNotification();
  },
  unmounted() {
    //clearInterval(this.checkNotification);
  },
  methods: {
    onMenuClick(index) {
      if (this.loginDoctorId == -1) {
        return;
      }
      switch (index) {
        case 0: { this.viewVisible = [true, false, false, false, false, false, false, false, false]; break; }
        case 1: { this.viewVisible = [false, true, false, false, false, false, false, false, false]; break; }
        case 2: { this.viewVisible = [false, false, true, false, false, false, false, false, false]; break; }
        case 3: { this.viewVisible = [false, false, false, true, false, false, false, false, false]; break; }
        case 4: { this.viewVisible = [false, false, false, false, true, false, false, false, false]; break; }
        case 5: { this.viewVisible = [false, false, false, false, false, true, false, false, false]; break; }
        case 6: { this.viewVisible = [false, false, false, false, false, false, true, false, false]; break; }
        case 7: { this.viewVisible = [false, false, false, false, false, false, false, true, false]; break; }
        case 8: { this.viewVisible = [false, false, false, false, false, false, false, false, true]; break; }
      }
    },
    setDoctorId(doctorId) {
      this.loginDoctorId = doctorId;
    },
    getDoctorId() {
      return this.loginDoctorId;
    },
    // checkNotification() {
    //   axios.get(BLENDER_BOT__GET_NOTIFICATION)
    //   .then((response) => {
    //       if (response.data.data.length == 0) {
    //         this.notification = '';
    //         this.$refs["app-middle-contents"].style.paddingTop = '0px';
    //       } else {
    //         if (Number(this.seenNotificationId) < response.data.data[0].id) {
    //           if (this.timeCheckForNotification(response.data.data[0].regDate)) {
    //             if (response.data.data[0].level == 1) {
    //               this.notificationLevvelClass = 'alert-danger';
    //             } else {
    //               this.notificationLevvelClass = 'alert-warning';
    //             }
    //             if (response.data.data[0].notification && response.data.data[0].notification.length > 0) {
    //               this.notification = response.data.data[0].notification;
    //               this.$refs["app-middle-contents"].style.paddingTop = '58px';
    //             }
    //             this.currentNotificationId = response.data.data[0].id;
    //           }
    //         }
    //       }
    //   })
    //   .catch((error) => {
    //       console.log(error);
    //   })
    //   .finally(() => {
    //   });
    // },
    // onNotificationClose() {
    //   this.notification = '';
    //   this.$refs["app-middle-contents"].style.paddingTop = '0px';
    //   localStorage.setItem("notification_id", JSON.stringify(this.currentNotificationId));
    //   this.seenNotificationId = JSON.parse(localStorage.getItem("notification_id")) || "";
    // },
    // timeCheckForNotification(regDate) {
    //   const nowTime = Date.now();
    //   const serverTime = new Date(regDate).getTime(); //+32400000 //서버와 9시간 차이 = 9 * 60 * 60 * 1000;
    //   const limitShowTime = serverTime + 43200000; //12시간 이내의 공지만 표시 = 12 * 60 * 60 * 1000;
    //   return nowTime <= limitShowTime;
    // }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Nanum+Gothic:400,700,800&display=swap');
@font-face {
    font-family: 'this_is_font_name';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts-20-12@1.0/SDSamliphopangche_Outline.woff') format('woff');
     font-weight: normal;
     font-style: normal;
}
.App {
  font-family: 'Nanum Gothic', sans-serif;
}
div.app-alert-area {
  position: fixed;
  z-index: 50;
  width: 100%;
}
div.app-middle-contents {
  position: relative;
  padding-top: 0px;
  z-index: 40;
}
ul.app-navi-area {
  list-style-type: none;
  background-color: #333;
  width: 50px;
  padding: 0;
  margin: 0;
  position: fixed;
  height: 100%;
  overflow: auto;
}
li.app-navi-area H4 {
  color: #fff;
  padding: 8px 15px 0px 15px;
  font-weight: bold;
}
li.app-navi-area H5 {
  color: #fff;
  padding: 0px 15px 50px 15px;
}
li.app-navi-area a {
  text-decoration: none;
  display: block;
  color: #fff;
  padding: 8px 15px 8px 15px;
  font-weight: bold;
}
li.app-navi-area a.selected {
  background-color: rgb(252, 209, 86);
  color: #000;
}
li.app-navi-area a:hover:not(.selected) {
  background-color:  #555;
  color: white;
}

div.app-contents-area {
  margin-left: 50px;
}



ul.navi-area {
  list-style-type: none;
  background-color: #ddd;
  width: 17%;
  padding: 10px;
  margin: 0;
  position: fixed;
  height: 100%;
  overflow: auto;
}
li.navi-area H4 {
  color: #000;
  padding: 8px 15px 0px 15px;
  font-weight: bold;
}
li.navi-area H5 {
  color: #000;
  padding: 0px 15px 50px 15px;
}
li.navi-area a {
  text-decoration: none;
  display: block;
  color: #000;
  padding: 8px 15px 8px 15px;
  font-weight: bold;
}
li.navi-area a.selected {
  background-color: rgb(252, 209, 86);
  color: #000;
}
li.navi-area a:hover:not(.selected) {
  background-color:  #555;
  color: white;
}

div.contents-area {
  margin-left: 17%;
  padding: 4px 0px 20px 20px;
}
</style>
