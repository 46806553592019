<template>
    <div id="container"  v-bind:style="{ marginTop: '0px' }">
        <div class="row" id="toast_container">
            <div class="col-md-12" v-bind:style="{}">
                <div class="alert alert-danger" role="alert" v-if="showAlert">{{textNotification}}</div>
                <div class="alert alert-success" role="alert" v-if="showConfirm">{{textNotification}}</div>
            </div>
        </div>
        <div v-bind:style="{ marginTop:(showAlert||showConfirm) ? '0px' : '74px'}">
            <label class="form-label" v-bind:style="{ margin: '4px', color: 'dodgerblue' }"><h3><strong>User Register</strong></h3></label>

            <div class="row" v-bind:style="{ margin: '4px' }">
                <div class="col-md-4">
                    <input id="input_user_name" type="text" class="form-control" :class="getInputClass(this.INPUT_TYPE.NAME)" v-bind:style="{ margin: '4px' }" placeholder="이름" inputmode="text" v-model="USER_INFO.name" @input="inputTextCheck($event, this.INPUT_TYPE.NAME)">
                    <input id="input_user_birth" type="text" class="form-control" :class="getInputClass(this.INPUT_TYPE.BIRTH)" v-bind:style="{ margin: '4px' }" placeholder="생년월일(2023-01-01)" inputmode="numeric" v-model="USER_INFO.birth" @input="inputTextCheck($event, this.INPUT_TYPE.BIRTH)">
                    <input id="input_user_phone" type="text" class="form-control" :class="getInputClass(this.INPUT_TYPE.PHONE)" v-bind:style="{ margin: '4px' }" placeholder="전화번호(010-0000-0000)" inputmode="numeric" v-model="USER_INFO.phone" @input="inputTextCheck($event, this.INPUT_TYPE.PHONE)">
                    <div class="dropdown" v-bind:style="{ marginLeft: '4px', marginRight:'-4px' }">
                        <button class="btn dropdown-toggle button-width-whole text-start" :class="getInputClass(this.INPUT_TYPE.HOSPITAL)" type="button" id="dropdownUser" data-bs-toggle="dropdown" aria-expanded="false">
                            {{ USER_INFO.hospital === "" ? '소속 병원' : USER_INFO.hospital }}
                        </button>
                        <ul class="dropdown-menu button-width" aria-labelledby="dropdownUser">
                            <li v-for="(hospital) in HOSPITAL" :key="hospital.id">
                                <a class="dropdown-item" href="#" @click="USER_INFO.hospital=hospital.desc; this.$refs['input_user_ptnum'].focus();">{{ hospital.desc }}</a>
                            </li>
                        </ul>
                    </div>
                    <input id="input_user_ptnum" ref="input_user_ptnum" type="text" class="form-control" :class="getInputClass(this.INPUT_TYPE.PTNUM)" v-bind:style="{ margin: '4px' }" placeholder="사용자 구분 정보(환자번호 등)" inputmode="text" v-model="USER_INFO.ptNum" @keypress.enter="save_to_server" @input="inputTextCheck($event, this.INPUT_TYPE.PTNUM)">
                    <input id="input_user_email" type="text" class="form-control" :class="getInputClass(this.INPUT_TYPE.EMAIL)" v-bind:style="{ margin: '4px' }" placeholder="[선택] email(name@email.com)" inputmode="email" v-model="USER_INFO.email" @keypress.enter="save_to_server" @input="inputTextCheck($event, this.INPUT_TYPE.EMAIL)">
                    <button class="btn btn-dark button-width-whole" type="button" v-bind:style="{ margin: '4px' }" @click="save_to_server">등록</button>
                </div>
            </div>
            <div class="row" v-bind:style="{ margin: '4px', marginTop:'20px' }">
                <div v-if="respRegDate" class="col-md-4">
                    <label class="form-label" v-bind:style="{ color: 'black', marginLeft:'8px' }">{{ " ・ 등록일시: " + respRegDate }}</label>
                    <label class="form-label" v-bind:style="{ color: 'crimson', marginLeft:'8px' }" v-html="' ・ 활성화코드: <strong>' + respAccessCode + '</strong>'"></label>
                    <p> </p>
                    <button class="btn btn-outline-primary button-width-whole" type="button" v-bind:style="{ margin: '4px' }" @click="send_sms">문자 전송</button>
                    <label class="form-label" v-bind:style="{ color: 'black', marginLeft:'8px' }" v-html="' ・ 해외문자로 발송됩니다. (스팸문자를 확인 필요)'"></label>
                    <label class="form-label" v-bind:style="{ color: 'black', marginLeft:'8px' }" v-html="' ・ 번호당 1회만 문자 전송할 수 있습니다.'"></label>
                    <p> </p>
                    <button class="btn btn-outline-dark button-width-whole" type="button" v-bind:style="{ margin: '4px' }" @click="reset_useInfo">확인 (입력필드 초기화)</button>
                    <div class="row" v-bind:style="{ margin: '4px', marginTop:'20px' }">
                        <div class="col-md-6" align="center">
                            <a href="https://play.google.com/store/apps/details?id=com.ismaker.behavioral_activation" target="_blank">Google Play Store</a><br />
                            <img :src="QR_ANDROID" width="200">
                        </div>
                        <div class="col-md-6" align="center">
                            <a href="https://apps.apple.com/us/app/%EB%82%B4%EB%A7%98/id6451176047" target="_blank">Apple App Store</a>
                            <img :src="QR_IOS" width="200">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
    import QrAndroid from '@/assets/qr_android.png';
    import QrIos from '@/assets/qr_ios.png';
    import axios from "axios";
    const SIMSIMI_BA__BASE_API = process.env.VUE_APP_SERVER_URI;
    const SIMSIMI_BA__WEB_REGIST = SIMSIMI_BA__BASE_API + "web_v1/user_regist";
    const SIMSIMI_BA__WEB_SEND_SMS = SIMSIMI_BA__BASE_API + "web_v1/send_sms";

    export default {
        name: 'ba_register',
        data() {
            return {
                doctorId: -1,
                USER_INFO: { name: '', birth: '', phone: '', hospital:'', ptNum:'', email:'' },
                INPUT_TYPE: { NAME:0, BIRTH:1, PHONE:2, HOSPITAL:3, PTNUM:4, EMAIL:5 },
                INPUT_INVALID: [0, 0, 0, 0, 0, 0],
                HOSPITAL: [{id: 'KU', desc:'고려'}, {id: 'SU', desc:'순천향'}],
                selectedHospital: {id: '', desc:''},

                respAccessCode: "",
                respRegDate: "",

                QR_ANDROID: QrAndroid,
                QR_IOS: QrIos,

                ALERT: 1,
                CONFIRM: 2,
                showAlert: false,
                showConfirm: false,
            }
        },
        mounted() {
            this.doctorId = this.$root.getDoctorId();
            if (this.doctorId == -1) {
                this.showMessage(this.ALERT, "로그인 해주세요", 2000);
            }
        },
        methods: {
            inputTextCheck(event, type) {
                this.INPUT_INVALID[type] = 0;
                const regexHangul = /[^ㄱ-ㅎ가-힣]+/;
                const regexNumber = /[^0-9-]+/;
                const regexArabicNumber = /[^a-zA-Z0-9]+/;
                const regexEmail = /[^a-zA-Z0-9.@]+/;
                switch(type) {
                    case this.INPUT_TYPE.NAME:
                        {
                            if (regexHangul.test(event.target.value)) {
                                this.showMessage(this.ALERT, "이름은 한글만 입력해주세요", 2000);
                                this.INPUT_INVALID[type] = -1;
                            }
                            if (event.target.value.length > 7) {
                                event.target.value = event.target.value.substring(0, 7);
                            }
                            event.target.value = event.target.value.trim();
                        }
                        break;
                    case this.INPUT_TYPE.BIRTH:
                        {
                            if (regexNumber.test(event.target.value)) {
                                this.showMessage(this.ALERT, "생년월일은 숫자만 입력해주세요", 2000);
                                this.INPUT_INVALID[type] = -1;
                            }
                            if (event.target.value.length > 10) {
                                event.target.value = event.target.value.substring(0, 10);
                            }
                            const tempBirth = event.target.value.trim().replace(/-/g, "");
                            const birthLength = tempBirth.length;
                            if (birthLength < 4) {
                                event.target.value = `${tempBirth.substring(0, 4)}`;
                            } else if (birthLength < 6) {
                                event.target.value = `${tempBirth.substring(0, 4)}-${tempBirth.substring(4, 6)}`;
                            } else {
                                event.target.value = `${tempBirth.substring(0, 4)}-${tempBirth.substring(4, 6)}-${tempBirth.substring(6, 9)}`;
                            }
                        }
                        break;
                    case this.INPUT_TYPE.PHONE:
                        {
                            if (regexNumber.test(event.target.value)) {
                                this.showMessage(this.ALERT, "전화번호는 숫자만 입력해주세요", 2000);
                                this.INPUT_INVALID[type] = -1;
                            }
                            if (event.target.value.length > 13) {
                                event.target.value = event.target.value.substring(0, 13);
                            }
                            const tempBirth = event.target.value.trim().replace(/-/g, "");
                            const birthLength = tempBirth.length;
                            if (birthLength < 3) {
                                event.target.value = `${tempBirth.substring(0, 3)}`;
                            } else if (birthLength < 7) {
                                event.target.value = `${tempBirth.substring(0, 3)}-${tempBirth.substring(3, 7)}`;
                            } else {
                                event.target.value = `${tempBirth.substring(0, 3)}-${tempBirth.substring(3, 7)}-${tempBirth.substring(7, 11)}`;
                            }
                        }
                        break;
                    case this.INPUT_TYPE.PTNUM:
                        {
                            if (regexArabicNumber.test(event.target.value)) {
                                this.showMessage(this.ALERT, "사용자 구분 정보는 영문, 숫자만 입력해주세요", 2000);
                                this.INPUT_INVALID[type] = -1;
                            }
                            if (event.target.value.length > 32) {
                                event.target.value = event.target.value.substring(0, 32);
                            }
                            event.target.value = event.target.value.trim();
                        }
                        break;
                    case this.INPUT_TYPE.EMAIL:
                        {
                            if (regexEmail.test(event.target.value)) {
                                this.showMessage(this.ALERT, "이메일은 영문, 숫자만 입력해주세요", 2000);
                                this.INPUT_INVALID[type] = -1;
                            }
                            if (event.target.value.length > 32) {
                                event.target.value = event.target.value.substring(0, 32);
                            }
                            event.target.value = event.target.value.trim();
                        }
                        break;
                }
            },
            getInputClass(type) {
                if (type === this.INPUT_TYPE.HOSPITAL) {
                    if (this.INPUT_INVALID[type] === -1) {
                        return "btn-outline-danger";
                    } else if (this.INPUT_INVALID[type] === 1) {
                        return "btn-outline-success";
                    } else {
                        return "btn-outline-dark";
                    }
                } else {
                    if (this.INPUT_INVALID[type] === -1) {
                        return "is-invalid";
                    } else if (this.INPUT_INVALID[type] === 1) {
                        return "is-valid";
                    }
                }
                return "";
            },
            save_to_server() {
                let errorMessage = "";
                const regexHangul = /[^ㄱ-ㅎ가-힣]+/;
                const regexNumber = /[^0-9-]+/;
                const regexArabicNumber = /[^a-zA-Z0-9]+/;
                const regexEmail = /[^a-zA-Z0-9.@]+/;
                const regexBirthPattern = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;
                const regexPhonePattern = /^[0-9]{3}-[0-9]{4}-[0-9]{4}$/;
                const regexEmailPattern = /^[a-zA-Z0-9.]+@[a-zA-Z0-9.]+$/;

                if (this.USER_INFO.name.length === 0) {
                    if (!errorMessage) errorMessage = "이름을 입력해 주세요";
                    this.INPUT_INVALID[this.INPUT_TYPE.NAME] = -1;
                } else if (regexHangul.test(this.USER_INFO.name)) {
                    if (!errorMessage) errorMessage = "이름은 한글만 입력해주세요";
                    this.INPUT_INVALID[this.INPUT_TYPE.NAME] = -1;
                }
                if (this.USER_INFO.birth.length === 0) {
                    if (!errorMessage) errorMessage = "생년월일 입력해 주세요";
                    this.INPUT_INVALID[this.INPUT_TYPE.BIRTH] = -1;
                } else if (regexNumber.test(this.USER_INFO.birth)) {
                    if (!errorMessage) errorMessage = "생년월일은 숫자만 입력해주세요";
                    this.INPUT_INVALID[this.INPUT_TYPE.BIRTH] = -1;
                } else if (regexBirthPattern.test(this.USER_INFO.birth) === false) {
                    if (!errorMessage) errorMessage = "생년월일의 형식을 확인해주세요";
                    this.INPUT_INVALID[this.INPUT_TYPE.BIRTH] = -1;
                }
                if (this.USER_INFO.phone.length === 0) {
                    if (!errorMessage) errorMessage = "전화번호를 입력해 주세요";
                    this.INPUT_INVALID[this.INPUT_TYPE.PHONE] = -1;
                } else if (regexNumber.test(this.USER_INFO.phone)) {
                    if (!errorMessage) errorMessage = "전화번호는 숫자만 입력해주세요";
                    this.INPUT_INVALID[this.INPUT_TYPE.PHONE] = -1;
                } else if (regexPhonePattern.test(this.USER_INFO.phone) === false) {
                    if (!errorMessage) errorMessage = "전화번호의 형식을 확인해주세요";
                    this.INPUT_INVALID[this.INPUT_TYPE.PHONE] = -1;
                }
                if (this.USER_INFO.hospital === "") {
                    if (!errorMessage) errorMessage = "소속 병원을 입력해 주세요";
                    this.INPUT_INVALID[this.INPUT_TYPE.HOSPITAL] = -1;
                }
                if (this.USER_INFO.ptNum.length === 0) {
                    if (!errorMessage) errorMessage = "사용자 구분 정보를 입력해 주세요";
                    this.INPUT_INVALID[this.INPUT_TYPE.PTNUM] = -1;
                } else if (regexArabicNumber.test(this.USER_INFO.ptNum)) {
                    if (!errorMessage) errorMessage = "사용자 구분 정보는 영문, 숫자만 입력해주세요";
                    this.INPUT_INVALID[this.INPUT_TYPE.PTNUM] = -1;
                }
                if (this.USER_INFO.email.length !== 0) {
                    if (regexEmail.test(this.USER_INFO.email)) {
                        if (!errorMessage) errorMessage = "이메일은 영문, 숫자만 입력해주세요";
                        this.INPUT_INVALID[this.INPUT_TYPE.EMAIL] = -1;
                    } else if (regexEmailPattern.test(this.USER_INFO.email) === false) {
                        if (!errorMessage) errorMessage = "이메일 형식을 확인해주세요";
                        this.INPUT_INVALID[this.INPUT_TYPE.EMAIL] = -1;
                    }
                }
                if (errorMessage) {
                    this.showMessage(this.ALERT, errorMessage, 2000);
                    return;
                }

                axios.post(SIMSIMI_BA__WEB_REGIST, {
                    "name": this.USER_INFO.name,
                    "birth": this.USER_INFO.birth,
                    "phone": this.USER_INFO.phone,
                    "hospital": this.USER_INFO.hospital,
                    "pid": this.USER_INFO.ptNum,
                    "email": this.USER_INFO.email,
                    "doctorId": this.doctorId,
                })
                    .then((response) => {
                        this.showMessage(this.CONFIRM, "등록되었습니다", 2000);
                        this.respAccessCode = response.data.accessCode;
                        this.respRegDate = response.data.regDate;
                    })
                    .catch(() => {
                        this.showMessage(this.ALERT, "오류가 발생했습니다", 2000);
                    })
                    .finally(() => {
                        //do something
                    });
            },
            send_sms() {
                axios.post(SIMSIMI_BA__WEB_SEND_SMS, {
                    "doctorId": this.doctorId,
                    "phone": this.USER_INFO.phone,
                    "name": this.USER_INFO.name,
                    "accessCode": this.respAccessCode
                })
                    .then(() => {
                        this.showMessage(this.CONFIRM, "발송되었습니다", 2000);
                    })
                    .catch((error) => {
                        if (error.response.status === 409) {
                            this.showMessage(this.ALERT, "이미 발송처리 되었습니다", 2000);
                        } else {
                            this.showMessage(this.ALERT, `오류가 발생했습니다\n${error}`, 2000);
                        }
                    })
                    .finally(() => {
                        //do something
                    });
            },
            reset_useInfo() {
                this.USER_INFO = { name: '', birth: '', phone: '', hospital:'', ptNum:'', email:'' };
                this.INPUT_INVALID = [0, 0, 0, 0, 0, 0];
                this.respAccessCode = "";
                this.respRegDate = "";
            },
            //Toast
            showMessage(type, msg, duration = 1500) {
                this.textNotification = msg;
                if (type == this.ALERT) {
                    this.showAlert = true;
                    setTimeout(() => {
                        this.showAlert = false;
                        this.textNotification = '';
                    }, duration);
                } else if (type == this.CONFIRM) {
                    this.showConfirm = true;
                    setTimeout(() => {
                        this.showConfirm = false;
                        this.textNotification = '';
                    }, duration);
                }
            },
        }
    }
</script>

<style>

</style>